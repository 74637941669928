import {
  EXTRACT_DATA_FROM_URL,
  UPDATE_ADULTS_COUNT,
  UPDATE_AGE_0TO6,
  UPDATE_AGE_7TO12,
  UPDATE_CHILDREN_COUNT,
  UPDATE_DATE_RANGE,
  UPDATE_ROOM_INFO,
} from "./actionTypes";

export const setUserData = (payload) => (dispatch) => {
  dispatch({ type: EXTRACT_DATA_FROM_URL, payload });
};

export const updateDateRange = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_DATE_RANGE, payload });
};
export const updateAdults = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_ADULTS_COUNT, payload });
};
export const updateChildren = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_CHILDREN_COUNT, payload });
};
export const updateRoomInfo = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_ROOM_INFO, payload });
};
export const updateAge0to6 = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_AGE_0TO6, payload });
};
export const updateAge7to12 = (payload) => (dispatch) => {
  dispatch({ type: UPDATE_AGE_7TO12, payload });
};
