import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../Components/HomePage/HomePage";
import LoginPage from "../Pages/LoginPage/LoginPage";
import BookingConfirmationPage from "../Pages/BookingConfirmationPage/BookingConfirmationPage";
import { PrivateRoute } from "./PrivateRoute";
import Voucher from "../Pages/Voucher/Voucher";
import PaymentForm from "../Pages/PaymentForm";
const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/booking"
        element={
          <PrivateRoute>
            <BookingConfirmationPage />
          </PrivateRoute>
        }
      />
      <Route path="/voucher" element={<Voucher />} />
      <Route exact path="/transaction" element={<PaymentForm />} />
    </Routes>
  );
};

export default AllRoutes;
