import React, { useContext, useEffect, useState } from "react";
import { HotelContext } from "../../Context/HotelContext";
import "./grosstotalCard.css";
import { useSelector } from "react-redux";
import KingBedIcon from "@mui/icons-material/KingBed";
import SensorDoorIcon from "@mui/icons-material/SensorDoor";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import CloseIcon from "@mui/icons-material/Close";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { calculateTotalPriceORTax } from "../../utilFunction/calculatePriceOrTax";
import { LoginContext } from "../../Context/UserLoginContext";
import { insertBookingInfoObj } from "../PayHotelPartial/insertBookingInfoObj";
import { insertBookingData } from "../../api/getBookingConfirmation.api";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { List } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const GrossTotalCard = ({
  appliedCoupons,
  selectedCoupon,
  handleAppliedCoupons,
}) => {
  const {
    selectedRoom,
    payHotel,
    toggleText,
    setToggleText,
    selectedRoomInfo,
    priceData,
    setPriceData,
    payPartialGross,
  } = useContext(HotelContext);
  const { userLoginData } = useContext(LoginContext);
  const singleHotelData = useSelector(
    (store) => store?.reducerHotel?.hotel?.hotel_data || {}
  );
  const enquiredData = useSelector(
    (store) => store?.urlReducer?.parmas_for_api || {}
  );
  const {
    checkintime = "",
    checkouttime = "",
    partial_tax_gst,
    partialamountpercentage,
    tax_info,
  } = useSelector((store) => store?.reducerHotel?.hotel?.hotel_data || {});
  const { to_date, from_date, no_of_adults, no_of_children } = useSelector(
    (store) => store?.urlReducer?.parmas_for_api || {}
  );
  const [formattedDateRange, setFormattedDateRange] = useState("");
  const [removeBookbtn, setRemoveBookBtn] = useState(true);
  const [loadingBooking, setLoadingBooking] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    const url = location.pathname;
    if (url === "/booking") {
      setRemoveBookBtn(true);
    }
    if (url === "/") {
      setRemoveBookBtn(false);
      setToggleText(true);
    }
  }, []);

  useEffect(() => {
    setPriceData(
      calculateTotalPriceORTax(
        "obj",
        selectedRoom,
        partialamountpercentage,
        tax_info,
        selectedCoupon
      )
    );
  }, [appliedCoupons, removeBookbtn, selectedRoom]);

  // Ensure that 'checkintime' is defined with a valid time in 24-hour format

  function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(":");
    let period = "AM";
    let adjustedHours = hours;

    if (hours >= 12) {
      period = "PM";
      if (hours > 12) {
        adjustedHours = hours - 12;
      }
    }

    return `${adjustedHours}:${minutes} ${period}`;
  }

  const checkIn = checkintime ? convertTo12HourFormat(checkintime) : "N/A";
  const checkOut = checkouttime ? convertTo12HourFormat(checkouttime) : "N/A";

  // const props = {
  //   email: userProfileDetails?.email,
  //   buyerPhone: userProfileDetails?.phone_number,
  //   fname: userProfileDetails?.first_name,
  //   lname: userProfileDetails?.last_name,
  //   BuyerPincode: "",
  //   city: "",
  //   state: "",
  //   country: "",
  //   buyerAddress: "",
  //   orderid: booking_id,
  //   amount: Number(
  //     priceAndSelectedRoomDetails.totalHotelSumAfterDisCount +
  //       priceAndSelectedRoomDetails.taxAndServiceObj?.totalTaxAmount
  //   )
  //     .toFixed()
  //     .toString(),
  //   customField1: "",
  //   currency: "356",
  //   isocurrency: "INR",
  //   chmod: "",
  //   tWallet: "",
  //   subtype: "",
  // };

  useEffect(() => {
    const formattedStartDate = formatDate(from_date);
    const formattedEndDate = formatDate(to_date);
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    setFormattedDateRange(formattedDateRange);
  }, [from_date, to_date, selectedRoom]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = getDayName(dateObj.getDay());
    const date = dateObj.getDate();
    const month = getMonthName(dateObj.getMonth());
    return `${day} ${date} ${month}`;
  };

  const getDayName = (dayIndex) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[dayIndex];
  };

  const handleAirpayPay = (partialId) => {
    const params = insertBookingInfoObj(
      selectedRoomInfo,
      userLoginData,
      singleHotelData,
      enquiredData,
      partialId,
      priceData
    );
    // insertBookingData(params, setBookingInfo, setLoadingBooking);
    axios
      .post("https://api.ratebotai.com:8443/insert_booking_data", params)
      .then((res) => {
        const {
          email,
          mobile_number,
          first_name,
          last_name,
          booking_id,
          gross_amount,
          paid_amount,
        } = res?.data?.data;
        navigate("/transaction", {
          state: {
            data: {
              email: email,
              buyerPhone: mobile_number,
              fname: first_name,
              lname: last_name,
              BuyerPincode: "",
              city: "",
              state: "",
              country: "",
              buyerAddress: "",
              orderid: booking_id,
              amount: Number(paid_amount).toFixed().toString(),
              customField1: "",
              currency: "356",
              isocurrency: "INR",
              chmod: "",
              tWallet: "",
              subtype: "",
            },
          },
        });
      });
  };

  // const calculateTotalPriceORTax = (str = "totalExTax") => {
  //   let no_of_rooms = selectedRoom?.occupancy_room_count;
  //   let no_of_nights = selectedRoom?.room_price_date_wise?.reduce(
  //     (acc, curr) => curr?.no_days + acc,
  //     0
  //   );
  //   let perRoomPrice = selectedRoom?.room_price_date_wise?.reduce(
  //     (acc, curr) => curr?.price + acc,
  //     0
  //   );

  //   if (str === "tax") {
  //     return perRoomPrice * no_of_rooms * 0.12;
  //   } else if (str === "totalIncTax") {
  //     return perRoomPrice * no_of_rooms * 0.12 + perRoomPrice * no_of_rooms;
  //   } else {
  //     return perRoomPrice * no_of_rooms;
  //   }
  // };

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  };

  return (
    <div className="total-container">
      <div>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ textAlign: "center", m: 1 }}
            >
              Coupons for you
            </Typography>
            <List>
              {selectedRoom?.coupons?.map((coupon, index) => (
                <div className="coupons-modal-div" key={(index = 1)}>
                  <p>{coupon?.coupon_name}</p>
                  <p>{coupon?.coupon_code}</p>
                  <button
                    style={{
                      // width:"20%",
                      borderRadius: "4px",
                      fontSize: "12px",
                      padding: "8px 20px",
                      border: "none",
                      backgroundColor:
                        appliedCoupons &&
                        selectedCoupon[0]?.coupon_code === coupon?.coupon_code
                          ? "white"
                          : "var(--secondary-color)",
                      color:
                        appliedCoupons &&
                        selectedCoupon[0]?.coupon_code === coupon?.coupon_code
                          ? "var(--secondary-color)"
                          : "white",
                    }}
                    onClick={() => {
                      let res = selectedRoom?.coupons?.some(
                        (coupont) =>
                          coupont?.coupon_code === coupon?.coupon_code
                      );
                      let selectedC = selectedRoom?.coupons?.filter(
                        (coupont) =>
                          coupont?.coupon_code === coupon?.coupon_code
                      );
                      handleAppliedCoupons(res, selectedC);
                      handleClose();
                    }}
                  >
                    {appliedCoupons &&
                    selectedCoupon[0]?.coupon_code === coupon.coupon_code
                      ? "Applied"
                      : "Apply"}
                  </button>
                </div>
              ))}
            </List>
          </Box>
        </Modal>
      </div>
      <div className="text">
        <h3 style={{ color: "#258039" }}>Your Stay</h3>
      </div>
      <div className="content-stay">
        <div className="checkin-checkout-cont">
          <div className="checkin total-card-text-style">
            <p className="text-color-check">Check-in</p>
            <p> {checkIn}</p>
          </div>
          <div className="checkin total-card-text-style">
            <p className="text-color-check">Check-out</p>
            {/* <p> {checkouttime} AM</p> */}
            <p>{checkOut}</p>
          </div>
        </div>
        <div className="to-from-container total-card-text-style">
          <p>{formattedDateRange}</p>
          <p>
            {no_of_adults} Adults, {no_of_children} Child
          </p>
        </div>
      </div>
      <div className="gross-total-bill-details">
        <div className="gross-room-icon">
          <MeetingRoomIcon
            style={{
              fontSize: "20px",
              marginRight: "10px",
              color: "var(--secondary-color)",
            }}
          />
          <p
            style={{
              fontSize: "14px",
              fontWeight: "600",
              color: "var(--secondary-color)",
            }}
          >
            {" "}
            {selectedRoom?.roomtype}
          </p>
        </div>
        <div className="gross-room-guest-count-cont text-color-check">
          <div>
            {selectedRoom?.occupancy_room_count}Rooms
            <CloseIcon style={{ fontSize: "16px" }} />
            {selectedRoom?.room_price_date_wise?.reduce(
              (acc, curr) => curr?.no_days + acc,
              0
            )}
            Nights
            <CloseIcon style={{ fontSize: "16px" }} />
            {no_of_adults + no_of_children} Guests
          </div>
          <div>
            ₹{priceData?.roomPrice}
            {/* {isNaN(calculateTotalPriceORTax("else", selectedRoom, 0, tax_info,selectedCoupon))
              ? 0
              : Math.ceil(
                  calculateTotalPriceORTax("else", selectedRoom, 0, tax_info,selectedCoupon)
                )} */}
          </div>
        </div>
        {appliedCoupons ? (
          <>
            <div
              className="gross-total-tax text-color-check"
              style={{ margin: "0 10px" }}
            >
              <p>{selectedCoupon[0]?.coupon_code}</p>
              {/* <p>price comming</p> */}

              <p
                style={{ color: "rgb(37, 128, 57)" }}
              >{`₹${priceData?.couponAmount} `}</p>
            </div>
            <div className="coupon-remove-div">
              <button
                style={{
                  background: "inherit",
                  border: "none",
                  fontWeight: "600",
                  color: "red",
                  fontSize: "9px",
                  height: "10px",
                  padding: "0",
                  margin: "0",
                }}
                onClick={() => handleAppliedCoupons(false, [])}
              >
                remove
              </button>
            </div>
          </>
        ) : (
          <div className="gross-total-tax text-color-check">
            <p>Coupons</p>
            <button
              style={{
                border: "none",
                background: "inherit",
                fontWeight: "600",
                color: "rgb(37, 128, 57)",
              }}
              onClick={handleOpen}
            >
              Add
            </button>
            {/* <p>{`₹${
            isNaN(calculateTotalPriceORTax("tax", selectedRoom, 0, tax_info))
              ? 0
              : Math.ceil(
                  calculateTotalPriceORTax("tax", selectedRoom, 0, tax_info)
                )
          } `}</p> */}
          </div>
        )}
        <div className="gross-total-tax text-color-check">
          <p>Taxes & Service Fees</p>
          <p>{`₹${priceData?.tax} `}</p>
        </div>
      </div>
      <div className="gross-total-card-price-cont text-color-check">
        <h3>Total</h3>
        <h3 style={{ color: "rgb(37, 128, 57)" }}>{`₹${priceData?.total}`}</h3>
      </div>
      <div className={removeBookbtn ? "dis-none" : ""}>
        <button
          onClick={() => navigate("/booking")}
          className="check-login-btn btn"
        >
          <span>continue</span>{" "}
        </button>
      </div>
      {/* {payPartialGross ? (
        <div className={`partial-pay-cont ${toggleText ? "dis-none" : ""}`}>
          <div>
            <p>Partial Amount without GST</p>
            <p>{`₹${priceData?.partialamount}`}</p>
          </div>
          <div>
            <p>Partial Amount GST 18%</p>
            <p>{`₹ ${priceData?.partialtax}`}</p>
          </div>
          <div>
            <p>Total Partial Amount to be paid</p>
            <h4 style={{ color: "rgb(37, 128, 57)" }}>{`₹ ${priceData?.partialamountwithtax}`}</h4>
          </div>
          <div className="gross-total-btn">
            <button onClick={() => handleAirpayPay("payPartial")}>Book</button>
          </div>
        </div>
      ) : (
        <div className="gross-total-btn">
          <button onClick={() => handleAirpayPay("payFull")}>Book</button>
        </div>
      )} */}
    </div>
  );
};

export default GrossTotalCard;
