import React, { useContext, useState } from "react";
import RoomCard from "./RoomCard";
import { useSelector } from "react-redux";
import "./roomcard.css";
import { HotelContext } from "../../Context/HotelContext";

const RoomsList = () => {
  const store = useSelector((store) => store?.reducerHotel?.hotel?.rooms_info);
  const singleHotelData = useSelector(
    (store) => store?.reducerHotel?.hotel?.hotel_data
  );
  const [roomsInfo, setRoomsInfo] = useState(store?.map((item) => item));

  return (
    <div className="room-list-container">
      {roomsInfo?.map((item, i) => (
        <RoomCard
          key={i + 1}
          item={item}
          id={i + 1}
          singleHotelData={singleHotelData}
        />
      ))}
    </div>
  );
};

export default RoomsList;
