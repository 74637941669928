import { BrowserRouter } from "react-router-dom";
import "./App.css";

import Layout from "./Components/Layout/Layout";
const App = () => {
  return (
    <div className="App">
      {/* <Loader /> */}
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </div>
  );
};

export default App;
