import axios from "axios";

const BASE_URL = "https://api.ratebotai.com:8443";
const API_GET_HOTEL_SNIPIT_DATA = BASE_URL + "/get_hotels_by_id";

export const getHotelById = async (params = {}, func) => {
  const res = await axios.post(API_GET_HOTEL_SNIPIT_DATA, { ...params });

  func(res.data.data);
};
