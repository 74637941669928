import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { getHotelAmenities } from "../../api/getAmenities.api";
import "./roominfomodal.css";
import GradeIcon from "@mui/icons-material/Grade";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "#F8F8F8",
  border: "2px solid #000",
  boxShadow: 24,
  // border:"5px solid red",
  p: 4,
};

const RoomInfoModal = ({
  amenitiesidstored,
  rooms_types_gallery,
  roomtype,
  maxpeople,
  rating_json,
  booking_info,
  hotel_gallery,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [amenities, setAmenities] = React.useState();
  const [aminitiesId, setAminitiesId] = React.useState("");
  const [toggleGallery, setToggleGallery] = useState(false);
  React.useEffect(() => {
    getHotelAmenities(aminitiesId, setAmenities);
  }, []);

  const handleGalery = (valueT) => {
    setToggleGallery(valueT);
  };
  return (
    <div>
      <p
        className="p-text-room1"
        onClick={handleOpen}
        style={{ marginTop: "8px", cursor: "pointer" }}
      >
        Amenities
      </p>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {/* <div className="modal-amenities-text-rating-wrapper">
              <div className="aminities-text">
                <h2>{roomtype}</h2>
                <h4>Sleeps {maxpeople}</h4>
              </div>
              <div className="amenities-rating-cont">
                <div>
                  <div className="modal-rating">
                    <p style={{ fontWeight: "600", marginRight: "5px" }}>
                      {" "}
                      {rating_json.rating}
                    </p>
                    <GradeIcon style={{ color: "white", fontSize: "16px" }} />
                  </div>
                </div>
                <p style={{ fontSize: "12px", fontWeight: "600" }}>
                  {booking_info?.last_booking}
                </p>
              </div>
            </div> */}

            <div className="modal-container">
              <div className="modal-left-container">
                <div className="amenities-container">
                  <h4
                    style={{
                      marginBottom: "12px",
                      color: "var(--secondary-color)",
                    }}
                  >
                    Amenities
                  </h4>
                  <div className="amenities-container-list">
                    {amenities?.map((item) => {
                      return (
                        <div className="amenites-single">
                          <img
                            src={item.file}
                            alt={item.lable}
                            width="25px"
                            height="25px"
                          />
                          <p style={{ fontSize: "12px", marginLeft: "10px" }}>
                            {item.lable}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {/* <div className="modal-right-container">
                <div className="modal-room-image">
                  <img
                    src={rooms_types_gallery[0]?.file}
                    alt={rooms_types_gallery[0]?.lable}
                  />
                  <div
                    className="modal-image-gallery-cont"
                    onClick={() => handleGalery(true)}
                  >
                    <CollectionsIcon className="modal-image-gallery" />
                  </div>
                </div>
              </div> */}
              <div
                className={`modal-gallery-cont ${
                  toggleGallery ? "display-gallery" : ""
                }`}
              >
                <div className="modal-gallery-wrapper">
                  {toggleGallery ? (
                    <SimpleImageSlider
                      key={2 + Math.random()}
                      width={596}
                      height={404}
                      images={hotel_gallery.map((item) => {
                        if (item) {
                          return { url: item.file };
                        }
                      })}
                      showBullets={true}
                      showNavs={true}
                    />
                  ) : null}
                </div>
                <div className="modal-gallery-close">
                  <CloseIcon
                    style={{ fontSize: "25px", cursor: "pointer" }}
                    onClick={() => handleGalery(false)}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default RoomInfoModal;
