import React, { useContext, useEffect, useState } from "react";
import "./loginpage.css";
import { MuiOtpInput } from "mui-one-time-password-input";
import { json, useNavigate } from "react-router-dom";
import { loginByOtp, verifyOtp } from "../../api/getLogin.api";
import { LoginContext } from "../../Context/UserLoginContext";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const [toggleComponent, setToggleComponent] = useState(false);
  const { userOtp, setUserOtp, setUserLoginData, setIsLoggedIn } =
    useContext(LoginContext);
  const hotel = useSelector((store) => store?.urlReducer?.selected_hotel);

  const [otp, setOtp] = useState("");
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const handleToggleChange = (e) => {
    e.preventDefault();
    if (inputValue.length > 9) {
      loginByOtp(
        { phone_number: inputValue, hotel_id: hotel?.hotel_id },
        setUserOtp
      );

      setToggleComponent(true);
    } else {
      setToggleComponent(false);
    }
  };
  useEffect(() => {}, [userOtp]);

  const handleOtpClick = () => {
    if (+userOtp === +otp) {
      verifyOtp({ phone_number: inputValue, otp }, setUserLoginData);
      setIsLoggedIn(true);
      localStorage.setItem("status", JSON.stringify(true));

      navigate("/booking");
    }
  };

  if (toggleComponent) {
    return (
      <div className="otp-wrap-cont-fields">
        <h2 className="title-otp-header-otp">Please enter the otp</h2>
        <div className="login-otp-container">
          <MuiOtpInput
            className="otp-cont-enter"
            value={otp}
            onChange={handleChange}
            style={{ marginTop: "1rem" }}
          />
          <button className="check-login-btn1 btn1" onClick={handleOtpClick}>
            Confirm
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="login-page-container ">
        <div className="login-page-form-wrapper box">
          <h2 style={{ textAlign: "left", marginBottom: "1rem" }}>
            Login / Signup
          </h2>
          <form>
            <div className="form-fields-wrapper">
              <div className="form-span">
                <span>Please enter your phone number to continue</span>
              </div>
              <div className="span-input">
                <input
                  className="input-otp-box"
                  placeholder="Please enter your phone number"
                  type="number"
                  required
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
              </div>
              <div className="form-continue-btn">
                <button
                  className="check-login-btn1 btn1"
                  onClick={handleToggleChange}
                >
                  Continue
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default LoginPage;
