export function constructRoomsInfo(roomConfigurations) {
    const roomInfo = [];
  
    for (let i = 0; i < roomConfigurations.length; i++) {
      const room = {
        no_of_adults: roomConfigurations[i].adults,
        child_0_to_6: roomConfigurations[i].child_0_to_6,
        child_7_to_12: roomConfigurations[i].child_7_to_12,
        no_of_child: roomConfigurations[i].child_0_to_6 + roomConfigurations[i].child_7_to_12,
      };
  
      roomInfo.push(room);
    }
  
    return roomInfo;
  }
  
  