import { createContext, useState } from "react";
export const HotelContext = createContext({});

export const HotelProvider = ({ children }) => {
  const [hotelData, setHotelData] = useState(undefined);
  const [paramsHotel, setParamsHotel] = useState({});
  const [selectedRoom, setSelectedRoom] = useState([]);
  const [selectedRoomInfo, setSelectedRoomInfo] = useState({});
  const [bookingInfo, setBookingInfo] = useState({});
  const [payHotel, setPayHotel] = useState(false);
  const [toggleText,setToggleText] = useState(true);
  const [payPartialGross,setPayPartialGross] = useState(true);
  const [priceData,setPriceData] = useState({})

  const value = {
    hotelData,
    setHotelData,
    paramsHotel,
    setParamsHotel,
    selectedRoom,
    setSelectedRoom,
    selectedRoomInfo,
    setSelectedRoomInfo,
    bookingInfo,
    setBookingInfo,
    payHotel,
    setPayHotel,
    toggleText,
    setToggleText,
    payPartialGross,
    setPayPartialGross,
    setPriceData,
    priceData
  };

  return (
    <HotelContext.Provider value={value}>{children}</HotelContext.Provider>
  );
};
