import React, { useState } from "react";
import GrossTotalCard from "../../Components/GrossTotalCard/GrossTotalCard";
import "./booking.css";
import BookingUserDetails from "../../Components/BookingUserDetails/BookingUserDetails";
import GrossTotalCardForBooking from "../../Components/GrossTotalCard/GrossTotalCardForBooking";

const BookingConfirmationPage = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div
        className="select-room-text select-room-text-t"
        style={{ margin: "auto", marginTop: "5rem" }}
      >
        Booking Confirmation
      </div>

      <div className="booking-left-right-cont">
        <div className="booking-left-cont">
          <BookingUserDetails toggle={toggle} setToggle={setToggle} />
        </div>
        <div className="booking-right-cont">
          <GrossTotalCardForBooking toggle={toggle} />
        </div>
      </div>
    </>
  );
};

export default BookingConfirmationPage;
