import axios from "axios";
import {
  GET_HOTEL_FAILURE,
  GET_HOTEL_REQUEST,
  GET_HOTEL_SUCCESS,
} from "./actionTypes";
import { UserDataTest } from "../../Components/Layout/testData";

const BASE_URL = "https://api.ratebotai.com:8443";
// https://api.ratebotai.com:8443/get_hotels_by_id

// Main Url for Production
const API_GET_HOTEL_SNIPIT_DATA = BASE_URL + "/get_hotels_by_id";
// ---------------------------------------

// URL for testing
// const API_GET_HOTEL_SNIPIT_DATA = "http://127.0.0.1:5000/get_hotel_snipit_data";

// the below function purpose is to remove duplicate hotel which are fetched from the data base

// function uniqByKeepLast(data, key) {
//   return [...new Map(data.map((x) => [key(x), x])).values()];
// }
// -----------end of the above function ------------------
export const getHotel =
  (params = {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_HOTEL_REQUEST });
      const res = await axios.post(API_GET_HOTEL_SNIPIT_DATA, {
        ...params,
      });
      if (res.data.data !== "{}") {
        dispatch({
          type: GET_HOTEL_SUCCESS,
          payload: {
            data: res.data.data,
            message: {
              message: res.data.message,
              status: res.data.status,
              status_message: res.data.status_message,
            },
          },
        });
      } else {
        dispatch({
          type: GET_HOTEL_SUCCESS,
          payload: {
            data: [],
            message: {
              message: res.data.message,
              status: res.data.status,
              status_message: res.data.status_message,
            },
          },
        });
      }
    } catch (err) {
      dispatch({ type: GET_HOTEL_FAILURE });
    }
  };

// import axios from "axios";
// import {
//   GET_HOTEL_FAILURE,
//   GET_HOTEL_REQUEST,
//   GET_HOTEL_SUCCESS,
// } from "./actionTypes";
// import { UserDataTest } from "../../Components/Layout/testData";

// const BASE_URL = "https://api.ratebotai.com:8443";
// const API_GET_HOTEL_SNIPIT_DATA = BASE_URL + "/get_hotels_by_id";

// export const getHotel = (params = {}) => async (dispatch) => {
//   let canceled = false; // Variable to track cancellation state

//   try {
//     dispatch({ type: GET_HOTEL_REQUEST });

//     const res = await axios.post(API_GET_HOTEL_SNIPIT_DATA, {
//       ...params,
//     });

//     if (!canceled) {
//       if (res.data.data !== "{}") {
//         dispatch({
//           type: GET_HOTEL_SUCCESS,
//           payload: {
//             data: res.data.data,
//             message: {
//               message: res.data.message,
//               status: res.data.status,
//               status_message: res.data.status_message,
//             },
//           },
//         });
//       } else {
//         dispatch({
//           type: GET_HOTEL_SUCCESS,
//           payload: {
//             data: [],
//             message: {
//               message: res.data.message,
//               status: res.data.status,
//               status_message: res.data.status_message,
//             },
//           },
//         });
//       }
//     }
//   } catch (err) {
//     dispatch({
//       type: GET_HOTEL_FAILURE,
//       payload: {
//         error: err.message, // Provide the error message or any relevant error information
//       },
//     });
//   }

//   return () => {
//     canceled = true; // Cancel the previous request when the effect is cleaned up
//   };
// };
