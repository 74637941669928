import React, { useContext, useRef } from "react";
import "./voucher.css";
import { HotelContext } from "../../Context/HotelContext";
import BookingDetails from "../../Components/BookingDetails/BookingDetails";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Voucher = () => {
  const { bookingInfo } = useContext(HotelContext);
  const divRef = useRef();

  const handleDownloadPDF = () => {
    const input = divRef.current;

    html2canvas(input, {
      useCORS: true,
      backgroundColor: null,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Specify the desired PDF size (for example, A4 size)
      const pdfWidth = 210; // A4 width in mm
      const pdfHeight = 297; // A4 height in mm
      const borderMargin = 10; // Margin for the border

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      // Calculate the scale to fit the canvas into the PDF, considering the border
      const imgWidth = pdfWidth - borderMargin * 2; // Adjust width for the border
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

      // Ensure the height does not exceed the PDF height minus the border
      if (imgHeight > pdfHeight - borderMargin * 2) {
        imgHeight = pdfHeight - borderMargin * 2; // Adjust height for the border
        imgWidth = (canvas.width * imgHeight) / canvas.height; // Maintain aspect ratio
      }

      // Add the image to the PDF
      pdf.addImage(
        imgData,
        "PNG",
        borderMargin,
        borderMargin,
        imgWidth,
        imgHeight
      );

      // Set the border properties
      pdf.setDrawColor(0); // Set border color (black)
      pdf.setLineWidth(0.5); // Set border width

      // Draw the border rectangle
      pdf.rect(
        borderMargin,
        borderMargin,
        pdfWidth - borderMargin * 2,
        pdfHeight - borderMargin * 2
      );

      // Save the PDF
      pdf.save("download.pdf");
    });
  };

  const Header = function (props) {
    const { bookInfo } = props;
    if (bookInfo?.booking_status === "confirmed") {
      return (
        <div className="voucher-stay-div">
          <div className="confirm text">
            <h2>Great! Your stay is confirmed.</h2>
          </div>
          <div className="confirm-sub-text">
            {/* <p>
              {" "}
              You will soon receive an email confirmation on {bookInfo?.email}
            </p> */}
          </div>
          <div className="print-button">
            <button
              className="check-login-btn"
              style={{ width: "110px" }}
              onClick={handleDownloadPDF}
            >
              Print
            </button>

            {/* <ButtonPrimary
              text="Print"
              link={`https://api.ratebotai.com:8443/booking_pdf?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`}
            /> */}
            {/* <ButtonPrimary text="Print" link={`https://api.ratebotai.com:8443/booking_pdf?booking_id=${location?.state?.data?.booking_id || booking_id}&customer_id=${location?.state?.data?.customer_id || custumer_id}`}/> */}
          </div>
        </div>
      );
    } else if (bookInfo?.booking_status === "cancelled") {
      return (
        <div className="cancelled text">Your booking has been cancelled</div>
      );
    } else if (bookInfo?.booking_status === "pending") {
      return (
        <>
          <div className="cancelled text">Your booking is in pending</div>
          <div className="print-button">
            {/* <ButtonPrimary text="Confirm Now" /> */}
          </div>
        </>
      );
    }
  };
  if (bookingInfo) {
    return (
      <div className="confirmation-page column-flex">
        <div className="header section">
          <Header bookInfo={bookingInfo} />
        </div>
        <div className="main-section-shadow" ref={divRef}>
          <BookingDetails bookInfo={bookingInfo} />
        </div>
      </div>
    );
  } else {
    return <div>Loading.......</div>;
  }
};

export default Voucher;
