import React, { useContext } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { HotelContext } from "../../Context/HotelContext";

const Bookbutton = ({
  id,
  handleSelect,
  selectedButton,
  handleToggle,
  roomtype,
  item,
}) => {
  const isSelected = selectedButton === id;

  const { selectedRoom } = useContext(HotelContext);

  let selectedR = selectedRoom?.roomtype?.split(" ")?.join("")?.toLowerCase();
  let incomingR = roomtype?.split(" ")?.join("")?.toLowerCase();

  const handleClick = () => {
    if (isSelected) {
      handleSelect(null); // Unselect the button
    } else {
      handleSelect(id); // Select the button
    }
  };
  console.log(selectedRoom, "this is selecyed room");

  const buttonStyle = {
    textTransform: "none",
    marginTop: "10px",
    backgroundColor:
      item?.occupancy_room_count === 0
        ? "#FF0000"
        : selectedR === incomingR
        ? "white"
        : "var(--secondary-color)",
    color: selectedR === incomingR ? "green" : "white",
    letterSpacing: "1px",
    boxShadow: "0 10px 20px -14px rgba(0, 0, 0,.7)",
    display: "flex",
    alignItems: "center",

    borderRadius: "4px",
    border: selectedR === incomingR ? "1px solid #5ca1e1" : "none",
    fontSize: "14px",
    fontWeight: "550",
  };

  //
  // background-color: #5ca1e1;
  // border: none;
  // color: #fff;
  // text-align: center;
  // font-size: 18px;
  // /* padding: 16px; */
  // width: 90%;
  // transition: all 0.5s;
  // cursor: pointer;
  // padding: 10px 0px;
  // text-transform: uppercase;
  // /* margin: 36px; */
  // box-shadow: 0 10px 20px -14px rgba(0, 0, 0,.7);

  return (
    <div>
      {item?.occupancy_room_count === 0 ? (
        <button
          className="room-booking-btn"
          style={buttonStyle}
          // onClick={handleClick}
        >
          {selectedR === incomingR && (
            <CheckCircleIcon style={{ marginRight: "5px", fontSize: "18px" }} />
          )}
          {"Sold Out"}
        </button>
      ) : (
        <button
          className="room-booking-btn"
          style={buttonStyle}
          onClick={handleClick}
        >
          {selectedR === incomingR && (
            <CheckCircleIcon style={{ marginRight: "5px", fontSize: "18px" }} />
          )}
          {selectedR === incomingR ? "Selected" : "Select"}
        </button>
      )}
    </div>
  );
};

export default Bookbutton;
