export const calculateTotalPriceORTax = (
  str = "totalExTax",
  selectedRoom,
  partialamountpercentage = 0,
  tax_info = [0],
  selectedCoupon = []
) => {
  // const { tax_value } = tax_info[0];
  const tax_value = 12;
  let originalPrice = selectedRoom?.room_price_date_wise?.reduce(
    (acc, curr) => curr?.price + acc,
    0
  );
  let perRoomPrice = 0;
  // let tax = 0;
  const priceTaxObj = {
    tax: 0,
    total: 0,
    roomPrice: 0,
    coupon_code: "",
    couponAmount: 0,
    coupontype: "",
    coupon_id: selectedCoupon[0]?.coupon_id || 0,
    no_of_rooms: selectedRoom?.occupancy_room_count,
    no_of_nights: selectedRoom?.room_price_date_wise?.reduce(
      (acc, curr) => curr?.no_days + acc,
      0
    ),
    partialPercent: partialamountpercentage,
    partialamount: 0,
    partialtax: 0,
    partialamountwithtax: 0,
  };
  let no_of_rooms = selectedRoom?.occupancy_room_count;
  let no_of_nights = selectedRoom?.room_price_date_wise?.reduce(
    (acc, curr) => curr?.no_days + acc,
    0
  );
  perRoomPrice =
    selectedRoom?.room_price_date_wise?.reduce(
      (acc, curr) => curr?.price + acc,
      0
    ) * no_of_rooms;

  if (
    selectedCoupon[0]?.coupon_code?.length > 2 &&
    selectedCoupon[0]?.coupon_discount_type === "percentage" &&
    str === "obj"
  ) {
    perRoomPrice =
      selectedRoom?.room_price_date_wise?.reduce(
        (acc, curr) => curr?.price + acc,
        0
      ) -
      perRoomPrice * no_of_rooms * (selectedCoupon[0]?.coupon_discount / 100);
    const couponAmount =
      originalPrice * no_of_rooms * (selectedCoupon[0]?.coupon_discount / 100);
    let tax =
      perRoomPrice *
      no_of_rooms *
      (+tax_info[0]?.tax_value / 100 || tax_value / 100);
    priceTaxObj.tax = Math.ceil(tax);
    priceTaxObj.total = Math.ceil(perRoomPrice + tax);
    priceTaxObj.roomPrice = perRoomPrice;
    priceTaxObj.coupon_code = selectedCoupon[0]?.coupon_code;
    priceTaxObj.couponAmount = Math.ceil(couponAmount);
    priceTaxObj.coupontype = selectedCoupon[0]?.coupon_discount_type;
    if (partialamountpercentage > 0) {
      priceTaxObj.partialamount = Math.ceil(
        priceTaxObj.total * (partialamountpercentage / 100)
      );
      priceTaxObj.partialtax = Math.ceil(priceTaxObj.partialamount * 0.18);
      priceTaxObj.partialamountwithtax = Math.ceil(
        priceTaxObj.partialamount + priceTaxObj.partialtax
      );
    }

    return priceTaxObj;
  } else if (
    selectedCoupon[0]?.coupon_code?.length > 2 &&
    selectedCoupon[0]?.coupon_discount_type === "amount" &&
    str === "obj"
  ) {
    perRoomPrice =
      selectedRoom?.room_price_date_wise?.reduce(
        (acc, curr) => curr?.price + acc,
        0
      ) - selectedCoupon[0]?.coupon_discount;

    let tax =
      perRoomPrice *
      no_of_rooms *
      (+tax_info[0]?.tax_value / 100 || tax_value / 100);
    const couponAmount = selectedCoupon[0]?.coupon_discount;
    priceTaxObj.tax = Math.ceil(tax);
    priceTaxObj.total = Math.ceil(perRoomPrice + tax);
    priceTaxObj.roomPrice = perRoomPrice;
    priceTaxObj.coupon_code = selectedCoupon[0]?.coupon_code;
    priceTaxObj.couponAmount = Math.ceil(couponAmount);
    priceTaxObj.coupontype = selectedCoupon[0]?.coupon_discount_type;
    if (partialamountpercentage > 0) {
      priceTaxObj.partialamount =
        priceTaxObj.total * (partialamountpercentage / 100);
      priceTaxObj.partialtax = priceTaxObj.partialamount * 0.18;
      priceTaxObj.partialamountwithtax =
        priceTaxObj.partialamount + priceTaxObj.partialtax;
    }
    return priceTaxObj;
  } else {
    let perRoomPrice =
      selectedRoom?.room_price_date_wise?.reduce(
        (acc, curr) => curr?.price + acc,
        0
      ) * no_of_rooms;
    let tax = perRoomPrice * (+tax_info[0]?.tax_value / 100 || tax_value / 100);

    // const couponAmount = selectedCoupon[0]?.coupon_discount
    priceTaxObj.tax = Math.ceil(tax);
    priceTaxObj.total = Math.ceil(perRoomPrice + tax);
    priceTaxObj.roomPrice = perRoomPrice;
    // priceTaxObj.coupon_code = selectedCoupon[0]?.coupon_code
    // priceTaxObj.couponAmount = couponAmount.toFixed(2)
    // priceTaxObj.coupontype = selectedCoupon[0]?.coupon_discount_type
    if (partialamountpercentage > 0) {
      priceTaxObj.partialamount =
        priceTaxObj.total * (partialamountpercentage / 100);
      priceTaxObj.partialtax = priceTaxObj.partialamount * 0.18;
      priceTaxObj.partialamountwithtax =
        priceTaxObj.partialamount + priceTaxObj.partialtax;
    }
    return priceTaxObj;
  }

  if (str === "totalIncTax") {
    return (
      perRoomPrice * no_of_rooms * (+tax_info[0]?.tax_value / 100) +
      perRoomPrice * no_of_rooms
    );
  } else if (str === "percentOfTotal") {
    return Math.ceil(
      (perRoomPrice * no_of_rooms * (+tax_info[0]?.tax_value / 100) +
        perRoomPrice * no_of_rooms) /
        partialamountpercentage
    );
  } else if (str === "percentOfTotalWithoutTax") {
    return (
      Math.ceil(
        (perRoomPrice * no_of_rooms * (+tax_info[0]?.tax_value / 100) +
          perRoomPrice * no_of_rooms) /
          partialamountpercentage
      ) * 0.18
    );
  } else if (str === "percentOfTotalWithTax") {
    return (
      Math.ceil(
        (perRoomPrice * no_of_rooms * (+tax_info[0]?.tax_value / 100) +
          perRoomPrice * no_of_rooms) /
          partialamountpercentage
      ) *
        0.18 +
      (perRoomPrice * no_of_rooms * (+tax_info[0]?.tax_value / 100) +
        perRoomPrice * no_of_rooms) /
        partialamountpercentage
    );
  } else if (str === "coupon") {
    if (selectedCoupon[0]?.coupon_discount_type === "percentage") {
      const couponAmount =
        originalPrice *
        no_of_rooms *
        (selectedCoupon[0]?.coupon_discount / 100);
      return couponAmount;
    } else {
      return selectedCoupon[0]?.coupon_discount;
    }
  } else {
    return perRoomPrice * no_of_rooms;
  }
};

// export const getPriceTaxObj = () => {
//   const
// }
